<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import vue2Dropzone from "vue2-dropzone";
/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "選舉頁面編輯",
      items: [
        {
          text: "選舉頁面專區",
          href: "/election",
        },
        {
          text: "設定列表",
          href: '/election',
        },
        {
          text: "選舉頁面編輯",
          active: true,
        }
      ],
    


      news_type: 0,
      news_platform: 0,
      send_broadcast: 0,

      value: '',
      date: '',
      time: '',
      visible: true,
      option: '',
      options: [
        "Alaska",
        "Hawaii",
        "California",
        "Nevada",
        "Oregon",
        "Washington",
        "Arizona",
        "Colorado",
        "Idaho",
        "Montana",
        "Nebraska",
        "New Mexico",
        "North Dakota",
        "Utah",
        "Wyoming",
        "Alabama",
        "Arkansas",
        "Illinois",
        "Iowa",
      ],

      relevant_option: ['tag', '稿號'],
      relevant_value: '',
      extension_value: '',

      guide_data: [
        {
          text: '',
          link: ''
        }
      ],

      second_data: [
        {
          main: '',
          sub: ''
        }
      ],
      tag_result: [],
      tag_options: [
        { id: 1, text: 'text' },
        { id: 2, text: 'text1' },
        { id: 3, text: 'text2' },
        { id: 4, text: 'text3' }
      ]

    };
  },
  computed: {

  },
  mounted () {

  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    vueDropzone: vue2Dropzone,
  },
  watch: {
    'mark_check' (_val) {
      if (_val !== 'accepted') {
        this.news_marked = []
      }
    }
  },
  methods: {
    changeStatus (_tabGroup, _status) {
      if (_tabGroup === 'news_type') {
        this.news_type = _status
      }
      if (_tabGroup === 'news_platform') {
        this.news_platform = _status
      }
      if (_tabGroup === 'send_broadcast') {
        this.send_broadcast = _status
      }
      // if (_tabGroup === 'news_marked') {
      //   if (this.news_marked.includes(_status)) {
      //     const index = this.news_marked.indexOf(_status)
      //     this.news_marked.splice(index, 1)
      //   } else {
      //     this.news_marked.push(_status)
      //   }
      // }

    },
    addNewGuide () {
      const new_one = {
        text: '',
        link: ''
      }
      if (this.guide_data.length < 3) {
        this.guide_data.push(new_one)
      }

    },
    deleteGuide (_index) {
      this.guide_data.splice(_index, 1)
    },


    addNewSecond () {
      const new_one = {
        main: '',
        sub: ''
      }
      this.second_data.push(new_one)
    },
    deleteSecond (_index) {
      this.second_data.splice(_index, 1)
    },

    addTag (newTag) {
      const tag = {
        text: newTag,
        id: newTag
      }
      this.tag_options.push(tag)
      this.tag_result.push(tag)
    }



  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary") 回上一層
    .col-lg-10
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 選舉設定

        b-card-body
          form.form-horizontal(role="form")
            .form-group.row.align-items-center
              .col-lg-2.pr-0
                label 名稱
              .col-lg-5.pl-0
                input.form-control(
                  v-model="option",
                  type="text",
                  placeholder="請輸入本次選舉名稱",
                )
            .form-group.row.align-items-center
              .col-lg-2.pr-0
                label 選舉類型

              .col-lg-5.pl-0
                input.form-control(
                  v-model="option",
                  type="text",
                  placeholder="請輸入本次選舉名稱",
                )
            .form-group.row.align-items-center
              .col-lg-2.pr-0
                label 投票日期

              .col-lg-5.pl-0
                date-picker(
                  v-model="date",
                  append-to-body,
                  lang="en",
                  confirm,
                  placeholder="請選擇投票日期"
                )
            .form-group.row.align-items-center.mb-1
              .col-lg-2.pr-0
                label TAG設定
              .col-lg-5.pl-0
                input.form-control(
                  v-model="option",
                  type="text",
                  placeholder="請輸入本次選舉要使用的主要tag(僅能輸入1組)",
                )
            .row.mb-3
              .col-lg-2
              .col-lg-20.pl-0
                span 本次設定的tag會由系統自動抓取，並將tag的新聞、影音、節目、投票…等，自動放置在頁面上


            .form-group.row.align-items-center.mb-3
              .col-lg-2.pr-0
                label seo-title
              .col-lg-5.pl-0
                input.form-control(
                  v-model="option",
                  type="text",
                  placeholder="請輸入標題",
                )
            .form-group.row.align-items-center.mb-3
              .col-lg-2.pr-0
                label seo-description
              .col-lg-5.pl-0
                textarea.form-control(
                  v-model="option",
                  rows="3",
                  type="text",
                  placeholder="請輸入此頁面的相關描述"
                )

            .form-group.row.align-items-center.mb-3
              .col-lg-2.pr-0
                label og-title
              .col-lg-5.pl-0
                input.form-control(
                  v-model="option",
                  type="text",
                  placeholder="請輸入分享在社群的標題",
                )
            .form-group.row.align-items-center.mb-3
              .col-lg-2.pr-0
                label og-description
              .col-lg-5.pl-0
                textarea.form-control(
                  v-model="option",
                  rows="3",
                  type="text",
                  placeholder="請輸入此頁面的相關描述"
                )

            .form-group.row.align-items-center.mb-3
              .col-lg-2.pr-0
                label og-image
              .col-lg-5.pl-0
                vue-dropzone#dropzone(ref="myVueDropzone", :use-custom-slot="true", :options="dropzoneOptions")
                  .dz-message.needsclick
                    i.h1.text-muted.ri-upload-cloud-2-line
                    h4 Upload Images
            
           
            .row 
              .col
                b-button.width-lg.mr-1(variant="primary") 儲存
                b-button.width-lg(variant="primary") 發佈


</template>
<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  i
    font-size: 30px

.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .select2-container--default
  display: block
  width: 100%
  height: calc(1.5em + 0.9rem + 2px)
  // padding: 0.45rem 0.9rem
  font-size: 0.9rem
  font-weight: 400
  line-height: 1.5
  color: #6c757d
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.2rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline: none

::v-deep .select2-selection--single
  border: none
  display: flex
  align-items: center
  height: 100%
</style>
